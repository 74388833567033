<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li><a href="javascript:void(0);">Account Verification </a></li>
        </ul>
      </div>
    </div>
    <section class="section--order-tracking">
      <div class="container">
        <h2 class="page__title"></h2>
        <div class="order-tracking__content">
          <div class="order-tracking__form text-center">
            <!-- <form>
              <div class="form-row">
                <div class="col-12 text-center">
                    <h3>You're ready to shopping!</h3>
                  <p>
                    <b> Congratulations,</b><br />
                    We've verified your account.
                  </p>
                </div>
                <div class="col-12 text">
                  <img
                    class="okpng mx-auto d-block"
                    src="../../../assets/img/ok.png"
                    alt="ok"
                  />
                </div>
                <div class="col-12 form-group-block">
                  <router-link class="btn ps-button" :to="{ name: 'login' }">
                    Continue
                  </router-link>
                </div>
              </div>
            </form> -->
            <div class="account-verification-successful container">
              <h2>Account Verification Successful</h2>
              <div class="success mt-3">
                <b-alert show dismissible variant="success"
                  >Your email has been successfully verified. You will be
                  redirected in a moment...

                  <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
                  
                </b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import ClipLoader  from "vue-spinner/src/ClipLoader.vue";
import { mapActions } from "vuex";
export default {
  components: {
    ClipLoader 
  },
  data() {
    return {
      code: "",
      color: '#e97027',
    };
  },

  mounted() {
    if (this.$route.params) {
      if (this.$route.params.code) {
        this.verifyAccount(this.$route.params.code);
        setTimeout( () => this.$router.push({ path: '/login'}), 3000);
      }
    }
  },
  methods: {
    ...mapActions("auth", ["verifyAccount"]),
  }
};
</script>
<style scoped>
.icon-check {
  width: 300px;
}
.okpng {
  width: 100px;
}
.order-tracking__form {
  max-width: 900px;
}
</style>